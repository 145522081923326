import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import PageTitle from './../Layout/PageTitle';
import ImgCarousel from './../Element/ImgCarousel';
import ClientCarousel from './../Element/ClientCarousel';
import {dlabBox} from './ShortCode/DataFile';

//Images
import bnr1 from './../../images/banner/banner1.jpg';

class ProjectDetails extends Component {
  componentDidMount() {
    var GalleryCategory = document.querySelectorAll('.box-hover');
    var fch = [].slice.call(GalleryCategory);

    for (var y = 0; y < fch.length; y++) {
            fch[y].addEventListener('mouseenter', function () {
        galleryActive(this);
      });
        }

    function galleryActive(current)
    {
            fch.forEach(el => el.classList.remove('active'));

      setTimeout(() => {
         current.classList.add('active');
      },  100);
    }
   }
    /* componentDidMount(){
        window.abc();
    } */
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='Magento' activeMenu='Magento' />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- Project Details --> */}
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="row m-b40">
                                    <div className="col-lg-6 col-md-6 m-b20">
                                        <h2 className="m-b10 m-t0">Magento Development</h2>
                                        <h5 className="m-b20">Building and driving sales for clients through robust Magento systems.</h5>
                                        <p className="m-b10">From a local startup store to global markets, Continental Tech helps you strategize and optimize your store with our robust Magento backend.</p>
                                        <p className="m-b10">We provide the tools and solutions for companies to succeed in the eCommerce realm, allowing you to focus on other aspects of your business.</p>
                                        <Link to={"./services-details"} className="site-button radius-xl outline">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
										<ImgCarousel />
                                    </div>
                                </div>
                                <div className="row m-b50">
                                    <div className="col-md-6 m-b20">
                                        <div className="p-a30 bg-gray">
                                            <h6>Project Name: <span className="m-l10 font-weight-300 text-gray-dark"> Porting to Magento 2.X </span></h6>
                                            <h6>Client: <span className="m-l10 font-weight-300 text-gray-dark"> Warner Entertainment</span></h6>
                                            <h6>Project Commencement Date: <span className="m-l10 font-weight-300 text-gray-dark"> August 18, 2018</span></h6>
                                            <h6>Priject Completion Date: <span className="m-l10 font-weight-300 text-gray-dark"> August 18, 2018</span></h6>
                                            <h6>Project url: <span className="m-l10 font-weight-300 text-gray-dark">www.example.com</span></h6>
                                        </div>
                                    </div>
                                    <div className="col-md-6 align-self-center">
                                        <h5 className="text-black fw5 m-b10">"When it was time to upgrade from Magento 1 to Magento 2, the thought of
                      									the process was daunting. Raj and the great people at Continental Tech
                      									took the time to fully explain and implement a plan that worked for us." - Mike Heart, Warner Entertainment</h5>
                                        <p className="m-b0">Our expert Magento developers are able to fully port your back end to Magento 2.X - regardless of the framework you use now. Our team will work tirelessly to port user information, product details, order history and more to a faster, more reliable system.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-full bg-black-light content-inner">
                                <div className="sort-title-bx text-white" data-name="Features">Features <i className="fa fa-angle-double-right"></i></div>
                                <div className="container">
                                    <div className="row">
                                      {dlabBox.map((item, index)=>(
                                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12" key={index}>
                                          <div className="dlab-box-bg m-b30 box-hover" style={{ backgroundImage: "url(" + item.image + ")", backgroundPosition: "center" }}>
                                            <div className="icon-bx-wraper center p-lr20 p-tb30">
                                              <div className="text-primary m-b30">
                                                <span className="icon-cell icon-sm">{item.icon}</span>
                                              </div>
                                              <div className="icon-content">
                                                <h5 className="dlab-tilte">{item.title}</h5>
                                                <p>{item.text}</p>
                                              </div>
                                            </div>
                                            <div className="icon-box-btn text-center">
                                              <Link to={"./services-details"} className="site-button btn-block">Read More</Link>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row m-b30">
                                    <div className="col-lg-4 col-md-12 align-self-center">
                                        <h5>Who We Are</h5>
                                        <h2 className="font-weight-700">Leading The Way For Over 12 Years</h2>
                                    </div>
                                    <div className="col-lg-8 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="90" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Support & Optimization</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="49" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">UX/UI Design</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="65" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Innovation</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
								<ClientCarousel />
                            </div>
                        </div>
                    </div>
                    {/* <!-- contact area END --> */}
                </div>
                <Footer />
            </>
        )
    }
}
export default ProjectDetails;
