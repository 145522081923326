import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Footer1 extends Component {
    render() {
        return (
            <>
                <footer className="site-footer text-uppercase">
                    <div className="footer-top bg-primary">
                        <div className="container" >
                            <div className="row">
                                <div className="col-5 col-lg-2 col-md-6 col-sm-6 footer-col-4">
                                    <div className="widget widget_services border-0">
                                        <h5 className="m-b30 text-white">Company</h5>
                                        <ul>
                                            <li><Link to={"./"}>Home </Link></li>
                                            <li><Link to={"./contact"}>Contact Us</Link></li>
                                            <li><Link to={"./about"}>About Us</Link></li>
                                            <li><Link to={"./services"}>Our Services</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-7 col-lg-2 col-md-6 col-sm-6 footer-col-4">
                                    <div className="widget widget_services border-0">
                                        <h5 className="m-b30 text-white">Useful Link</h5>
                                        <ul>
                                            <li><Link to={"./magento"}>Magento</Link></li>
                                            <li><Link to={"./portfolio"}>Portfolio</Link></li>
                                            <li><Link to={"./partners"}>Partners</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4">
                                    <div className="widget widget_getintuch">
                                        <h5 className="m-b30 text-white ">Contact us</h5>
                                        <ul>
                                            {/*<li><i className="ti-location-pin"></i><strong>address</strong> demo address #8901 Marmora Road Chi Minh City, Vietnam </li>*/}
                                            <li><i className="ti-mobile"></i><strong>phone</strong>1 512 225 8775 </li>
                                            <li><i className="ti-email"></i><strong>email</strong>info@continentaltech.com</li>
                                            <li><i className="ti-location-pin"></i><strong>locations</strong>Austin, Texas <br/> Woodland Park, Colorado <br/> </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4 ">
                                    <div className="widget">
                                        <h5 className="m-b30 text-white" data-drip-attribute="headline">Subscribe To Our Newsletter</h5>
                                        <div data-drip-attribute="description"></div>
                                        <p className="text-capitalize m-b20">If you have any questions, you can contact with us so that we can give you a satisfying answer. Subscribe to our newsletter to get our latest products.</p>
                                        <div className="subscribe-form m-b20">
                                            <form className="dzSubscribe" data-drip-embedded-form="943790009" action="https://www.getdrip.com/forms/943790009/submissions" method="post">
                                                <div className="dzSubscribeMsg"></div>
                                                <div className="input-group">
                                                    <input name="fields[email]" required="required" className="form-control" placeholder="Your Email" type="email" />
                                                    <span className="input-group-btn">
                                                        <button name="submit" value="Submit" type="submit" data-drip-attribute="sign-up-button" className="site-button">Subscribe</button>
                                                    </span>
                                                </div>
                                            </form>
                                        </div>
                                        <ul className="list-inline m-a0">
                                            <li><a target='_blank' rel="noreferrer" href={"https://www.facebook.com/continentaltechllc"} className="site-button facebook circle mr-1"><i className="fa fa-facebook"></i></a></li>
                                            <li><a target='_blank' rel="noreferrer" href={"https://www.linkedin.com/company/continental-tech-llc"} className="site-button linkedin circle mr-1"><i className="fa fa-linkedin"></i></a></li>
                                            <li><a target='_blank' rel="noreferrer" href={"https://www.instagram.com/continentaltechllc/"} className="site-button instagram circle mr-1"><i className="fa fa-instagram"></i></a></li>
                                            <li><a target='_blank' rel="noreferrer" href={"https://twitter.com/ContlTechLLC"} className="site-button twitter circle mr-1"><i className="fa fa-twitter"></i></a></li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom bg-primary">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 text-left "> <span>Copyright © 2021 Continental Tech</span> </div>

                                <div className="col-lg-6 col-md-6 col-sm-6 text-right ">
                                    <div className="widget-link ">
                                        <ul>
                                            <li><Link to={"https://support.continentaltech.com/"}> Help Desk</Link></li>
                                            <li className="ml-1"><Link to={"#"}> Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer1;
