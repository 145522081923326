import React, { useEffect } from 'react';
import bnr from './../../images/under-construction.jpg';
import bnr2 from './../../images/banner/bnr2.jpg';
import logo from './../../images/logo1.png';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import PageTitle from './../Layout/PageTitle';


const UnderMaintenanceCrypto =()=>  {
	let resizeMargin = () => {
		//var bgImag =  "background-image: url("+ bnr + ");";
		var screenHeight = window.innerHeight;
		try{
			var doc = document.getElementsByClassName('full-height');
			console.log(doc[0]);
			doc[0].setAttribute("style", 'height:'+ screenHeight+'px;');
		}catch(e){
			console.log(e);
		}
	};

	useEffect(() => {
		resizeMargin();
		window.addEventListener('resize', resizeMargin);
	}, []);

	return (
		<>
			<Header />
			<div className="bg-white">
			<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr2 + ")" }}>
				<PageTitle motherMenu='Blockchain' activeMenu='Blockchain' />
			</div>
				<div className="under-construction" style={{"backgroundImage":"url("+ bnr +")",backgroundRepeat:'no-repeat',backgroundSize:"100%",backgroundPosition:"bottom"}}>
					<div className="container">
						<div className="row full-height">
							<div className="col-md-12 col-lg-12 text-center header-ind">
								<div className="">
									<div className="logo-header">
										<img src={logo} alt="" />
									</div>
								</div>
								<h2>
									<span>Page Under</span> <br /> Maintenance
								</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}
export default UnderMaintenanceCrypto;
