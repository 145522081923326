import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Slider from "react-slick";

import Slider1 from './../../images/main-slider/bg-5.jpg';
import Slider2 from './../../images/main-slider/bg-3.jpg';
import Slider3 from './../../images/main-slider/bg-4.jpg';


function SampleNextArrow(props) {
  const { onClick } = props;
  return (
  	<div className="owl-nav">
		<div className="owl-next la la-angle-right"  onClick={onClick}/>
	</div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
	<div className="owl-nav">
		<div className=" owl-prev la la-angle-left" onClick={onClick} style={{zIndex:1 }}/>
	</div>
  );
}

class HomeOwlSlider extends Component{

	render(){
		var settings = {
			arrows: true,
			dots: true,
            slidesToShow: 1,
            infinite: true,
			autoplay: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 1,

				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 1,

				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,

				  }
				}
			]
        };
		return(

			<Slider className="owl-slider owl-carousel owl-theme owl-none" {...settings}>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider1} className="w-100" alt=""/></div>
					<div className="slide-content overlay-primary">
						<div className="slide-content-box container">
							<div className="max-w600 text-white">
								<h2 className="text-white font-weight-400">We turn great ideas into<br/> functional products. <br/></h2>
                <p>From apps to e-commerce sites, our products work for you.</p>
								<Link to ={"./contact"} className="site-button m-r10 white button-lg">Get Started</Link>
								<Link to ={"./services"} className="site-button outline outline-2 button-lg">How It Works</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider3} className="w-100" alt=""/></div>
					<div className="slide-content overlay-primary">
						<div className="slide-content-box container">
							<div className="max-w600 text-white">
								<h2 className="text-white font-weight-400">Robust eCommerce solutions - <br/> from local shops, to national brands. <br/></h2>
								<p>We're' passionate about creating an eCommerce experience that both benefits our clients, and blows away our clients customers. Our sites are smart, attractive and built to last. </p>
								<Link to ={"./contact"} className="site-button m-r10 white button-lg">Get Started</Link>
								<Link to ={"./services"} className="site-button outline outline-2 button-lg">How It Works</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider2} className="w-100" alt=""/></div>
					<div className="slide-content overlay-primary">
						<div className="slide-content-box container">
							<div className="max-w600 text-white">
								<h2 className="text-white font-weight-400">A team that moves quickly<br/> and with pride, whatever we do. </h2>
								<p>A forward-thinking, talented group of individuals is at your fingertips when you work with Continental Tech.</p>
								<Link to ={"./contact"} className="site-button m-r10 white button-lg">Get Started</Link>
								<Link to ={"./services"} className="site-button outline outline-2 button-lg">How It Works</Link>
							</div>
						</div>
					</div>
				</div>
			</Slider>
		)

	}

}

export default HomeOwlSlider;
