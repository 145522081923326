import React from 'react';

import services1  from './../../../images/our-services/develop.jpg';
import services2  from './../../../images/our-services/plug.svg';
import services3  from './../../../images/our-services/migration.png';
import services4  from './../../../images/our-services/cloud.png';
import services5  from './../../../images/our-services/updated.jpg';
import services6  from './../../../images/our-services/responsive.jpg';
import services7  from './../../../images/our-services/speed.png';
import services8  from './../../../images/our-services/ecommerce.jpg';

import work1 from './../../../images/our-work/pic1.jpg';
import work2 from './../../../images/our-work/pic2.jpg';
import work3 from './../../../images/our-work/pic3.jpg';
import work4 from './../../../images/our-work/pic4.jpg';


const wraperBlog = [
	{icon: <i className="ti-headphone-alt" />, title: 'Market forecast'},
	{icon: <i className="ti-ruler-pencil" />, title: 'Most Versatile'},
	{icon: <i className="ti-desktop" />, title: 'Workflow automation'},
];

const wraperBox2= [
	{icon: <i className="ti-desktop" />, title:'Lifetime Updates',},
	{icon: <i className="ti-ruler-pencil" />, title:'Fast Supports',},
	{icon: <i className="ti-palette" />, title:'Interactive Elements',},
	{icon: <i className="ti-file" />, title:'Flexible Layout',},
];

const dlabBox=[
	{icon: <i className="ti-ruler-pencil" />, title:'B2B & B2C Development', image: services1, text:"Architecture for eCommerce can be intimidating, our team of developers are experts in complex order processes, shipping, and more.", },
	{icon: <i className="ti-direction-alt" />, title:'Custom Plugins and Third-Party Integration', image: services2,text:"Integrate your store however you want with custom 3rd party integrations and well-tailored Magento plugins.",  },
	{icon: <i className="ti-files" />, title:'Quick & Professional Migration', image: services3, text:"Our experts transfer all your store data, customer, data, and orders to the Magento 2 framework - regardles of platform.",  },
	{icon: <i className="ti-cloud" />, title:'Robust eCommerce Cloud Services', image: services4, text:"We build our clients custom scalable cloud architecture specifically tailored to your needs with Magento-optimized cloud servers. ",  },
	{icon: <i className="ti-paint-roller" />, title:'Magento Maintenance & Updates', image: services5, text:"We provide top-of-the-line maintenanceand updates to all our clients - even if we didnt originally design the site.", },
	{icon: <i className="ti-mobile" />, title:'Mobile & Responsive Design', image: services6,text:"Our developers boost sales by creating robust mobile-first responsive designs that help customers find exactly what they're looking for.",  },
	{icon: <i className="ti-bolt" />, title:'Optimized for Speed and User Experience', image: services7, text:"Out sites and databases are built to be light and fast, take your site to the next level by boosting SEO and lowering customer bounce rates.",  },
	{icon: <i className="ti-shopping-cart" />, title:'Multi-Channel Shopping Cart', image: services8, text:"Improve your stores reach by publishing your items across different platforms.",  },
];

const wraperBox3 = [
	{icon: <i className="flaticon-devices" />, title:'Web Design Solutions'  },
	{icon: <i className="flaticon-pen" />, title:'Web Development Solutions'  },
	{icon: <i className="flaticon-bar-chart" />, title:'SEO/SMO Solutions'  },
	{icon: <i className="flaticon-file" />, title:'Pre Customized Solutions'  },
	{icon: <i className="flaticon-notebook" />, title:'Project Maintenance'  },
	{icon: <i className="flaticon-team" />, title:'Social Networking Portal'  },
];

const wraperBox4=[
	{icon: <i className="flaticon-devices" />, title: 'Custom Applications'},
	{icon: <i className="flaticon-pen " />, title: 'Dynamic Website'},
	{icon: <i className="flaticon-layers" />, title: 'CMS Development'},
	{icon: <i className="flaticon-shop" />, title: 'PHP based E-commercet'},
	{icon: <i className="flaticon-technology" />, title: 'CMS Development'},
	{icon: <i className="flaticon-internet" />, title: 'Cloud (SaaS, PaaS)'},
];

const dlabBox2 =[
	{icon: <i className="flaticon-diamond" />, title:'Business', image: work1,  },
	{icon: <i className="flaticon-pen" />, title:'Consulting', image: work2,  },
	{icon: <i className="flaticon-file" />, title:'Finance', image: work3,  },
	{icon: <i className="flaticon-bar-chart" />, title:'Growth', image: work4,  },
];

const wraperBox5= [
	{icon: <i className="ti-check-box" />, title: 'Ensure Security',},
	{icon: <i className="ti-user" />, title: 'Expert Team',},
	{icon: <i className="ti-headphone-alt" />, title: '24/7 SUPPORT',},
];

const wraperBox6 = [
	{icon: <i className="flaticon-bar-chart" />, title: 'Make it Simple',},
	{icon: <i className="flaticon-trophy" />, title: 'Unique design',},
	{icon: <i className="flaticon-devices" />, title: 'True Responsiveness',},
];
const wraperBox7 = [
	{icon: <i className="flaticon-pen" />, title: 'Creative',},
	{icon: <i className="flaticon-diamond" />, title: 'Unique design',},
	{icon: <i className="flaticon-devices" />, title: 'Innovative',},
];

const wraperBox8 = [
	{icon1: <i className="flaticon-trophy" /> , title1: 'Elegant / Unique design', icon2: <i className="flaticon-technology" />, title2: 'Different Layout Type',},
	{icon1: <i className="flaticon-bar-chart" />, title1: 'Make it Simple', icon2: <i className="flaticon-devices" />, title2: 'True Responsiveness',},
	{icon1: <i className="flaticon-trophy" />, title1: 'Elegant / Unique design', icon2: <i className="flaticon-technology" />, title2: 'Different Layout Type',},
	{icon1: <i className="flaticon-bar-chart" />, title1: 'Make it Simple', icon2: <i className="flaticon-devices" />, title2: 'True Responsiveness',},
];



export {
	wraperBlog,
	wraperBox2,
	wraperBox3,
	wraperBox4,
	wraperBox5,
	wraperBox6,
	wraperBox7,
	wraperBox8,
	dlabBox,
	dlabBox2,
};
