import React,{Fragment,  useState} from 'react';
import {Link} from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';

//Images
import pic5 from './../../images/about/pic5.jpg';

function Para(){
	return(
		<>
			<div className="abuot-box p-lr30">
				<p> Our services cover a wide variety of use cases at a variety of pricepoints to achieve exactly what our client wants. From high quality eCommerce to fully-fledged marketing-optimized websites, Continental Tech has you covered.  </p>
			</div>
		</>
	)
}

const HomeTab = (props) => {
	const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

	return(
		<Fragment>
			<div className="container">
				<div className="tab-content chosesus-content">
					<div id="cost" className="tab-pane active">
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<div className="row p-tb50">
									<div className="col-lg-6 m-b10 col-md-4 about-img " >
										<img src={pic5} alt="" />
									</div>
									<div className="col-lg-6 col-md-8 text-white">
										<div className="p-l30 " >
											<h4 className="font-weight-300">Benefits</h4>
											<ul className="list-checked primary">
												<li><span>Cost Effectiveness</span></li>
												<li><span>Flexible Engagement Models</span></li>
												<li><span>Cutting-edge Technology</span></li>
												<li><span>Custom eCommerce Distributions</span></li>
											</ul>
										</div>
										<Para />
									</div>
								</div>
							</TabPane>

						</TabContent>
					</div>
				</div>
			</div>
		</Fragment>
	)

}
export default HomeTab;
