import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
import SectionCounter from './../../Element/SectionCounter';
import TestimonialCarousel from './../../Element/TestimonialCarousel';
import FormStyle from './../../Element/FormStyle';

//Images
import bnr1 from './../../../images/banner/bnr4.jpg';
import bgmap from './../../../images/background/bg-map.jpg';
import bg1 from './../../../images/background/bg1.jpg';
import about from './../../../images/about/pic3.jpg';

const iconWraper=[
	{icon: <i className="flaticon-pen" />, title:'Custom Applications'},
	{icon: <i className="flaticon-devices" />, title:'Dynamic Websites'},
	{icon: <i className="flaticon-layers" />, title:'CMS Development'},
	{icon: <i className="flaticon-shop" />, title:'Magento 2.X eCommerce'},
	{icon: <i className="flaticon-technology" />, title:'Tech Modernization'},
	{icon: <i className="flaticon-internet" />, title:'Cloud (SaaS, PaaS)'},
];

const titleBlog=[
	{title1:'Web', title2: 'Portals', text: "We create attractive, function web portals for your company using the latest technology.",},
	{title1:'Mobile', title2: 'Web Services', text: "Mobile-first development practices allows your users to get the most out of the mobile experience.",},
	{title1:'Backend', title2: 'Solutions', text: "Out talented team of developers create strong, secure, and scalable backend environments.",},
	{title1:'Social Network', title2: 'Development', text: "Boost company interaction - add a social aspect to your company with out knowledge of social networks.",},
	{title1:'eCommerce', title2: 'Solutions', text: "Take your store to the next level with our world-class eCommerce frameworks.",},
	{title1:'Custom', title2: 'Web Apps', text: "Elevate your customer experience with custom forms, apps, and widgets!",},
];

class ServicesDetails extends Component {


    render() {
        return (
            <>
                <Header />

                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='Service Details' activeMenu='Service Details' />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full content-inner exp-services">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-head text-black text-center">
                                            <h4 className="text-gray-dark font-weight-500 m-b10">Technologies</h4>
                                            <h2 className="box-title m-tb0">Scalable, interactive & custom tech solutions - your way<span className="bg-primary"></span></h2>
                                            <p className="font-weight-300 font-18">Within a span of <strong className="text-primary">12</strong> years, Continental Tech has emerged as one of the leading tech companies teeming with experienced, talented developers. The innovation and unmatched expertise of our team of <strong className="text-yellow">55+</strong> strong and dedicated developers have helped turn <strong className="text-red">60+</strong> companies into modern, technically suficient powerhouses. We support clients from all over the world including USA, UK, and Spain.</p>
                                        </div>
                                        <div className="row serv">

																					{iconWraper.map((data,index)=>(
																						<div className="col-lg-2 col-md-4 col-sm-6 m-b30" key={index}>
																							<div className="icon-bx-wraper expertise  bx-style-1 p-lr10 p-tb20 center">
																								<div className="icon-bx-sm bg-primary radius m-b20">
																									<Link to={"#"} className="icon-cell">{data.icon}</Link>
																								</div>
																								<div className="icon-content">
																									<h5 className="dlab-tilte"><Link to={"#"}>{data.title}</Link></h5>
																								</div>
																							</div>
																						</div>
																					))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Our Services --> */}
                        {/* <!-- About Us --> */}
                        <div className="section-full">
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    <div className="col-lg-6 col-md-12 p-a0 img-fix">
                                        <img src={about} alt="" className="img-cover" />
                                    </div>
                                    <div className="col-lg-6 col-md-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell ">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h3 className="box-title m-b10">Helping companies find their footing in a digital world<span className="bg-primary"></span></h3>
                                                        <h5 className="m-b30">Since our foundation in 2009, our goal has been to use digital technology to create real experiences.</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 chosesus-content">
                                                        <ul className="list-checked primary text-white">
                                                            <li><span>Modernization of company systems</span></li>
                                                            <li><span>Optimization of the company's online presence</span></li>
                                                            <li><span>Custom websites, web/mobile apps, or plugins to reach your goals</span></li>
                                                        </ul>
                                                        <p>We work with out clients every step of the way when it comes to upgrading technology for your business. Some companies out there are cold and quiet when it comes to communication. At Continental Tech, keeping up with clients if of the utmost importance.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- About Us End --> */}
                        {/* <!-- About Us --> */}
                        <div className="section-full content-inner">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h4 className="text-gray-dark m-b10">Services</h4>
                                    <h2 className="box-title m-tb0">Development Services<span className="bg-primary"></span></h2>
                                    <p>We offer a wide variety of services from development to modernization. Feel free to contact us and inquire about other services.</p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row ">
									{titleBlog.map((data,index)=>(
										<div className="col-lg-4 col-md-6 col-sm-6 m-b30" key={index}>
											<div className="icon-bx-wraper expertise  bx-style-1 p-a30 center">
												<div className="icon-content">
													<h4 className="dlab-tilte text-uppercase"><Link to={"#"}>{data.title1}	<br/>{data.title2}</Link></h4>
													<p>{data.text}</p>
												</div>
											</div>
										</div>
									))}
                                </div>
                            </div>
                        </div>
                        {/* <!-- Our Services --> */}
						{/* <!-- Why Chose Us --> */}
							<SectionCounter />
                        {/* <!-- Why Chose Us End --> */}
                        {/* <!-- Testimonials --> */}
                        <div className="section-full content-inner"   style={{ backgroundImage: "url(" + bgmap + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 ">
									<TestimonialCarousel />
                                </div>
                            </div>
                        </div>
                        {/* <!-- Testimonials END --> */}
                        {/* <!-- Get in touch --> */}
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                        {/* <!-- Get in touch --> */}
                    </div>
                    {/* <!-- contact area END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default ServicesDetails;
