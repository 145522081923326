import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';

// var bg1 = require('images/banner/bnr2.jpg');
import bnr1 from './../../../images/banner/bnr2.jpg';
import bnr2 from './../../../images/background/bg1.png';
import about3 from './../../../images/about/pic3.jpg';
import peakprinting from './../../../images/about/peakprinting.png';
import peakprintinggirl from './../../../images/about/peakprintinggirl.png';
import bnr4 from './../../../images/background/bg1.jpg';


class Aboutus2 extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='Partners' activeMenu='Partners' />
                    </div>
                    <div className="content-block">
						<div className="section-full bg-white content-inner" style={{ backgroundImage: "url(" + bnr2 + ")" }}>
                            <div className="container">
															<div className="section-head text-black text-center">
																	<img src={peakprinting} alt="Peak Printing Logo" className="m-b10" style={{ maxWidth: "300px" }}/>
																	<p>Commercial, promotional, and garment printing solutions. Peak Printing can create just about any promotional item you can imagine.</p>
															</div>
                                <div className="section-content">
                                    <div className="row">
                                        {/* <div className="col-6 col-lg-3 col-md-6 col-sm-6">
                                            <div className="icon-bx-wraper">
                                                <div className="icon-md text-black m-b20">
                                                    <Link to ={"#"} className="icon-cell text-black"><i className="flaticon-trophy"></i></Link>
                                                </div>
                                                <div className="icon-content m-b30">
                                                    <h5 className="dlab-tilte">Elegant / Unique design</h5>
                                                    <p>Creating UI designs that are as new and exciting as Cryptocurrency itself.</p>
                                                </div>
                                            </div>
                                            <div className="icon-bx-wraper m-b30">
                                                <div className="icon-md text-black m-b20">
                                                    <Link to ={"#"} className="icon-cell text-black"><i className="flaticon-technology"></i></Link>
                                                </div>
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">Future of Payment</h5>
                                                    <p>Foward-thinking, secure solutions that put the power of payment in your hands.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-3 col-md-6 col-sm-6">
                                            <div className="icon-bx-wraper">
                                                <div className="icon-md text-black m-b20">
                                                    <Link to ={"#"} className="icon-cell text-black"><i className="flaticon-bar-chart"></i></Link>
                                                </div>
                                                <div className="icon-content m-b30">
                                                    <h5 className="dlab-tilte">Keep it Simple</h5>
                                                    <p>Forget the complexities of digital currency - pay the way you're already used to.</p>
                                                </div>
                                            </div>
                                            <div className="icon-bx-wraper  m-b30">
                                                <div className="icon-md text-black m-b20">
                                                    <Link to ={"#"} className="icon-cell text-black"><i className="flaticon-devices"></i></Link>
                                                </div>
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">Future of Crypto</h5>
                                                    <p>Laying the foundation for Cryptocurrency to be used as a daily currency.</p>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-12 col-lg-6 col-md-12 col-sm-12 m-b30 my-auto mx-auto h-100">
                                            <div className="dlab-media gradient radius-sm h-100">
                                            <img src={peakprintinggirl} alt="Peak Printing info" className="m-b10" style={{ maxWidth: "800px" }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-full ">
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    <div className="col-lg-6 col-md-6 col-xs-12 p-a0 img-fix">
                                        <img src={about3} alt="" className="img-cover" />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-xs-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h2>Our partners help bring the future to your doorstep</h2>
                                                        <h5 className="m-b30">Embracing the latest in modern technology.</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 text-gray">
                                                        <p>At Continental Tech, we work with only the best and the brightest. Our partners are experienced innovators in each of their fields. Professionalism, determination, and results lay the foundation for our relationship.</p>
                                                        <Link to ={"./services-details"} className="site-button radius-xl outline white">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="section-full content-inner" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                            <div className="container">
                                <div className="section-head text-black text-center">
																		<img src={garizon} alt="Novapago Logo" className="m-b10" style={{ maxWidth: "300px" }}/>
                                    <p>We are extremely proud to partner with Garizon as lead. </p>
                                </div>
                                <div className="row text-center">
																		{imageblog.map((item,index)=>(
																			<div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
																				<div className="dlab-box">
																					<div className="dlab-media dlab-img-effect rotate"> <Link to ={"#"}><img src={item.image} alt="" /></Link> </div>
																					<div className="dlab-info p-a30 border-1 bg-white">
																						<h5 className="dlab-title m-t0"><Link to ={"#"}>{item.title}</Link></h5>
																						<p className="m-b0">{item.text}</p>
																					</div>
																				</div>
																			</div>
																		))}
                                </div>
                            </div>
                        </div> */}
                        <div className="section-full content-inner overlay-primary bg-img-fix text-white p-tb80" style={{ backgroundImage: "url(" + bnr4 + ")" }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center max-w600 m-auto ">
                                            <div className="m-b20"><i className="fa fa-quote-left font-45 text-primary"></i></div>
                                            <h4 className="text-uppercase font-weight-700 font-30">We would love to hear your idea, lets start your new project.</h4>
                                            <Link to={"./services"} className="site-button radius-xl outline outline-2"><span className="p-lr10">Start New Projects</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </>
        )
    }
}
export default Aboutus2;
