import React, {Component} from 'react';
import Slider from "react-slick";




class TestimonialCarousel extends Component{
	render(){
		var settings = {
			arrows: false,
            slidesToShow: 3,
            infinite: true,
			dots: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					slidesToShow:1,
				  }
				}
			]
        };
		return(
			<>
				<Slider className="testimonial-box center-active-owl item-center owl-loaded owl-carousel owl-none  owl-dots-black-full " {...settings}>
					<div className="item">
						<div className="testimonial-8">
							<div className="testimonial-text">
								<p>
									"For almost 10 yeas, Continental Tech has been a trusted and experienced
									eCommerce partner. From maintaining our European store to developing or
									North American store, Tim and his team have always provided excellent
									work and fantastic communication to keep our platforms growing."
								</p>
							</div>
							<div className="testimonial-detail clearfix">
								<h5 className="testimonial-name m-t0 m-b5">Mark Payne</h5>
								<span>Napalm Records America</span>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="testimonial-8">
							<div className="testimonial-text ">
								<p>
									"When it was time to upgrade from Magento 1 to Magento 2, the thought of
									the process was daunting. Raj and the great people at Continental Tech
									took the time to fully explain and implement a plan that worked for us.
									Now we can concentrate on what matters - continuing to build our business"
								</p>
							</div>
							<div className="testimonial-detail clearfix">
								<h5 className="testimonial-name m-t0 m-b5">Mike Heart </h5>
								<span>Warner Entertainment</span>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="testimonial-8">
							<div className="testimonial-text">
								<p>
									"From idea to launch, Continental Tech gave us the scalable platform we
									 needed to support our future growth. Highly recommended."
								</p>
							</div>
							<div className="testimonial-detail clearfix">
								<h5 className="testimonial-name m-t0 m-b5">Ed Carmack </h5>
								<span>Creative Destiny</span>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="testimonial-8">
							<div className="testimonial-text ">
								<p>
									"The last tech firm we used was cold and unresponsive to our needs. With
									Continental Tech, we feel we have an actual partner who cares"
								</p>
							</div>
							<div className="testimonial-detail clearfix">
								<h5 className="testimonial-name m-t0 m-b5">Erik Youngs </h5>
								<span>Voodoo Chef</span>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="testimonial-8">
							<div className="testimonial-text">
								<p>
									"I wish I knew about Continental Tech earlier. They offer rapid service
									with knowledgeable technicians who respect all experience levels."
								</p>
							</div>
							<div className="testimonial-detail clearfix">
								<h5 className="testimonial-name m-t0 m-b5">Susan Anthony </h5>
								<span>Sew Business</span>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="testimonial-8">
							<div className="testimonial-text">
								<p>
								"Before we partnered with Continental Tech, we had an outdated IT
								infrastructure. Now, we are state-of-the-art, and we could not have done
								it without them."
								</p>
							</div>
							<div className="testimonial-detail clearfix">
								<h5 className="testimonial-name m-t0 m-b5">Mark Little </h5>
								<span>Fulfillment Genius</span>
							</div>
						</div>
					</div>
					<div className="item">
						<div className="testimonial-8">
							<div className="testimonial-text">
								<p>
								"Using a mixed development environment of Mac and Microsoft applications, overseeing
								these can be a challenge for some firms. Continental Tech and their team
								make it work seamlessly."

								</p>
							</div>
							<div className="testimonial-detail clearfix">
								<h5 className="testimonial-name m-t0 m-b5">Robert Hallstrome </h5>
								<span>Sony Gaming</span>
							</div>
						</div>
					</div>

				</Slider>
			</>
		)
	}

}

export default TestimonialCarousel;
